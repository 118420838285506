<template>
  <section
    ref="refSubscribeBlock"
    class="subscribe-block"
  >
    <div class="container">
      <div class="subscribe-block__content">
        <div class="subscribe-block__left">
          <div
            class="subscribe-block__title h2"
            v-html="values.title"
          />
          <div
            v-if="values.subtitle"
            class="subscribe-block__message h5 semibold"
            v-html="values.subtitle"
          />
          <div
            class="subscribe-block__form"
            :class="{'--error': $v.$error}"
          >
            <input
              v-model="formSubmit.email"
              class="h5"
              type="text"
              placeholder="Ваша почта"
            />
            <button
              class="btn btn-primary"
              :class="{'--loading': isSending}"
              :disabled="isSending"
              @click="submitForm"
            >
              {{ isSending ? 'Секундочку...' : 'Подписаться' }}
            </button>
          </div>
        </div>
        <div class="subscribe-block__right">
          <div
            v-if="phoneNumber"
            class="phone-section"
          >
            <div class="phone-section__label h6">Позвоните нам</div>
            <a
              :href="`tel:${ phoneNumber }`"
              class="phone-section__value h3 semibold hover-decoration"
              target="_blank"
            >
              {{ getPhoneNumberFormat(phoneNumber) }}
            </a>
          </div>
          <div
            v-if="whatsappNumber"
            class="phone-section"
          >
            <div class="phone-section__label h6">Напишите в WhatsApp</div>
            <a
              :href="`https://api.whatsapp.com/send?phone=${whatsappNumber}`"
              rel="nofollow"
              class="phone-section__value h3 semibold hover-decoration"
              target="_blank"
            >{{ getPhoneNumberFormat(whatsappNumber) }}</a>
          </div>
          <div
            v-if="socialsList.length"
            class="phone-section"
          >
            <div class="phone-section__label h6">Следите за нами</div>
            <div class="phone-section__socials">
              <template
                v-for="(_item) in socialsList"
                :key="`socialsList-${_item.alt}`"
              >
                <a
                  target="_blank"
                  :href="_item.link"
                  rel="nofollow"
                >
                  <img
                    :src="useAsset(_item.icon)"
                    :alt="_item.alt"
                  />
                </a>
              </template>
            </div>
          </div>
        </div>

        <div class="subscribe-block__round">
          <svg
            width="159"
            height="38"
            viewBox="0 0 159 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M41.7251 20.5215C45.5435 23.5039 49.3179 26.4531 53.0913 29.0859C60.5061 34.2578 69.6604 37.3145 79.5662 37.3145C89.4719 37.3145 98.6262 34.2578 106.041 29.0859C109.814 26.4531 113.589 23.5039 117.407 20.5215C130.236 10.498 143.561 0.0878906 159 0H0C15.4956 0.0292969 28.8594 10.4688 41.7251 20.5215Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import { useAsset } from '~/composables/useAssets';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { useCommonStore } from '~/store/common';
import {
  getPhoneNumberClear,
  getPhoneNumberFormat
} from '~/utils/phoneNumber';
import { useNotificationModal } from '~/composables/useNotificationModal';

const {
  $api,
  $toast
} = useNuxtApp();
const props = defineProps<IBlockDefaultProps>();
const appConfig = appConfigStore();
const commonStore = useCommonStore();
const notificationModal = useNotificationModal();

const refSubscribeBlock: any = ref(null);

const phoneNumber = computed(() => getPhoneNumberClear(String(props?.extras?.phone || appConfig?.vars?.['Телефон'] || appConfig?.config?.phone || '')));
const whatsappNumber = computed(() => String(appConfig?.vars?.Whatsapp || appConfig?.config?.Whatsapp || ''));
const socialsList = computed(() => {
  const list = [];
  if (appConfig.config?.vkontakte) {
    list.push({
      alt: 'vk',
      link: appConfig.config?.vkontakte,
      icon: '/assets/img/svg/social-media/vk.svg'
    });
  }
  if (appConfig.config?.instagram) {
    list.push({
      alt: 'instagram',
      link: appConfig.config?.instagram,
      icon: '/assets/img/svg/social-media/instagram.svg'
    });
  }
  if (appConfig.config?.facebook) {
    list.push({
      alt: 'zen.yandex.ru',
      link: appConfig.config?.facebook,
      icon: '/assets/img/svg/social-media/dzen.svg'
    });
  }
  if (appConfig.config?.telegram) {
    list.push({
      alt: 'telegram',
      link: appConfig.config?.telegram,
      icon: '/assets/img/svg/social-media/telegram.svg'
    });
  }
  return list;
});

const formSubmit = ref({
  email: ''
});
const $v = useVuelidate({
  email: {
    email: helpers.withMessage('Неверный формат', email),
    required: helpers.withMessage('Обязательно к заполнению', required)
  }
}, formSubmit);

const isSending: Ref<boolean> = ref(false);
const submitForm = async () => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  isSending.value = true;

  const groups = await $api.agent.get('/subscribe/groups').then((res) => res?._data).catch(() => []);

  const subscribePromises = groups.map(async (group: any) => {
    const body = {
      group_id: group.id,
      email: formSubmit.value.email
    };

    try {
      await $api.agent.post('/subscribe', { body });
      return true;
    } catch (error) {
      return false;
    }
  });

  await Promise.all(subscribePromises);

  formSubmit.value.email = '';
  $v.value.$reset();
  isSending.value = false;

  notificationModal.openNotificationModal({
    title: "Уведомление",
    message: "Подписка на новости успешно оформлена"
  });
  // $toast.success('Подписка на новости успешно оформлена');
};

onMounted(() => {
  const parentElement = refSubscribeBlock.value?.parentElement;
  if (!parentElement) {
    return;
  }
  parentElement.classList.add('subscribe-block-root');
  const rootChildren = parentElement?.parentElement.querySelectorAll(':scope > div');
  rootChildren.forEach((element: any) => {
    if (!element.classList.contains('subscribe-block-root')) {
      element.classList.add('subscribe-block-neighbour');
    }
  });
});
</script>

<style lang="scss">
.subscribe-block-root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: -400px;
  &:after {
    content: "";
    float: left;
    padding-top: 400px;
  }
}

.subscribe-block-neighbour {
	position: relative;
	z-index: 1;
	background-color: white;
}

.subscribe-block {
	margin: 62px 0;
	margin-bottom: -40px;
	padding: 64px 0;
	padding-bottom: 104px;
	box-sizing: border-box;
	background-color: #F4F3F1;
	//position: relative;
	//overflow: hidden;
	border-radius: 20px 20px 0 0;

  position: sticky;
  top: 20%;
  z-index: 0;
}

.subscribe-block__content {
	display: flex;
}

.subscribe-block__left {
	flex: 1;
}

.subscribe-block__right {
	display: flex;
	flex-direction: column;
	margin-left: 40px;
	width: 100%;
	max-width: 415px;

	& > * {
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}
	}
}

.subscribe-block__round {
	position: absolute;
	top: 0;
	left: 60%;
	pointer-events: none;
}

.subscribe-block__title {
	max-width: 610px;
	margin-bottom: 16px;
}

.subscribe-block__message {
	margin-bottom: 40px;
}

.subscribe-block__form {
	display: flex;
	height: 70px;
	width: 100%;
	max-width: 702px;
	border-radius: 16px;
	border: 1px solid #B8B8B8;
	padding: 4px;
	box-sizing: border-box;

	input {
		flex: 1;
		color: #000;
		padding: 0 24px;
		box-sizing: border-box;

		&::placeholder {
			opacity: 0.5;
		}
	}

	.btn {
		padding: 12px 45px;
		border-radius: 16px;
	}

	&.--error {
		border-color: #ED908C;

		input {
			color: #ED908C;
		}
	}
}

.phone-section {
}

.phone-section__label {
	margin-bottom: 6px;
}

.phone-section__value {
}

.phone-section__socials {
	display: flex;
	flex-wrap: wrap;
	margin-top: -16px;
	margin-left: -16px;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		margin-top: 16px;
		margin-left: 16px;
		background-color: #404040;
		border-radius: 8px;
		padding: 5px;
		box-sizing: border-box;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			filter: invert(1);
		}

		&:hover {
			background-color: #2F2E2E;
		}
	}
}

@media (max-width: 1699px) {
	.subscribe-block__right {
		max-width: 342px;
	}
}

@media (max-width: 1499px) {
	.subscribe-block__right {
		max-width: 305px;
	}
}

@media (max-width: 1023px) {
  .subscribe-block-root {
    margin-bottom: -1000px;
    &:after {
      padding-top: 1000px;
    }
  }

	.subscribe-block {
    top: 10%;
		margin: 0;
		padding: 40px 0;
	}
	.subscribe-block__content {
		flex-direction: column;
	}
	.subscribe-block__title {
		text-align: center;
	}
	.subscribe-block__message {
		margin-bottom: 31px;
		text-align: center;
		font-weight: 500 !important;
	}
	.subscribe-block__form {
		flex-direction: column;
		border-radius: 0;
		border: none;
		max-width: initial;
		height: initial;
		padding: 0;

		input {
			margin-bottom: 16px;
			border-radius: 12px;
			border: 1px solid #B8B8B8;
			padding: 14.5px 24px;

			color: black;
			font-size: 12px;
			font-weight: 500;
			line-height: 140%;

			&::placeholder {
				color: #989898;
			}
		}

		.btn {
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 12px;
		}

		&.--error {
			input {
				border-color: #ED908C;
			}
		}
	}
	.subscribe-block__round {
		display: none;
	}
	.subscribe-block__right {
		margin-left: 0;
		max-width: initial;
		margin-top: 35px;
	}
	.phone-section {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.phone-section__label {
		text-align: center;
	}
	.phone-section__value {
		text-align: center;
		font-size: 20px;
	}
	.phone-section__socials {
		justify-content: center;
	}
}
</style>
